.step-item {
    @apply relative flex flex-col justify-center items-center w-72;
}
.step-item:not(:first-child):before {
    @apply content-lineIcon absolute right-[87%] top-[10%] ;
}
.step {
    @apply w-9 h-9 flex mx-6 items-center justify-center z-10 relative bg-[#2B2D31] rounded-full text-xs font-normal text-[#7E858F];
}
.active .step {
    @apply bg-[#4E408E] w-[100px] px-2 h-9 mr-6  text-white  border border-[#826AED]   ;
}
.complete .step {
    @apply bg-[#72DE94]  w-9 h-9 border border-[#56A76F];
}
.complete p {
    @apply text-white;
}
.complete:not(:first-child):before,
.active:not(:first-child):before {

}
.btn {
    @apply mt-3 bg-gray-800 hover:bg-gray-700 text-gray-100 font-medium px-7 py-1.5 rounded;
}
