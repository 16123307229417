@tailwind base;
@tailwind components;
@tailwind utilities;
body{
  background: #020409 !important;
}
#root {
  overflow-x: hidden !important;
}
.signup-wrapper{
  width: 100vw;
  height: 100vh;
  background: #1a2038;
  padding-top: 35vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1300;
  overflow-y: scroll;
}

.drawer-overlap-wrapper{
  width: 100vw;
  height: 100vh;
  background: #1a2038;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 2vh;
  z-index: 2000;
}

.select-organization-login{
  width: 50%;
  margin: 0 auto;
}

.signup-cardbox{
  width: 100%;
  max-width: 545px;
  margin: 0 auto;
  border-radius: .5em;
  background: #fff;
  display: flex;
}

.d-flex{
  display: flex;
}

.px-1{
  padding: 0 1rem;
}

.p-1{
  padding: 1rem;
}

.p-2{
  padding: 2rem;
}

.mt-1{
  margin-top: 1rem;
}

.mt-2{
  margin-top: 2rem;
}

.mt-3{
  margin-top: 3rem;
}

.mt-4{
  margin-top: 4rem;
}

.mt-5{
  margin-top: 5rem;
}

.text-end{
  text-align: right;
}

.signup-form-box{
  background: rgba(0, 0, 0, .08);
  padding: 3em 1em;
}

.pointer{
  cursor: pointer;
}

.font-small{
  font-size: .8em !important;
}

.text-muted{
  font-size: .9em;
  color: darkgray;
}

.title-mark::before{
  content: '';
  display: inline-block;
  border-left: .2em solid #7467ef;
  height: 1em;
  padding-right: .5em;
}

.card-heading p{
  margin-top: -14px;
}

.pl-1{
  padding-left: 1rem;
}

.pl-2{
  padding-left: 2rem;
}

.pl-3{
  padding-left: 3rem;
}

.pt-1{
  padding-top: 1rem;
}

.pt-2{
  padding-top: 2rem;
}

.pt-3{
  padding-top: 3rem;
}

.ml-1{
  margin-left: 1rem;
}

.ml-2{
  margin-left: 2rem;;
}

.ml-3{
  margin-left: 3rem;
}

.resource-text{
  display: inline-block;
  position: relative;
  top: -.5em;
}

.appbar-org-container{
  display: inline-block;
  padding: .5em;
  color: rgba(255, 255, 255, .9);
  position: absolute;
  z-index: 9;
  top: 12px;
}

.text-center{
  text-align: center;
}

.text-no-transform{
  text-transform: none;
}

.review-data-wrapper{
  padding: 1em;
  background-color: #f9f9f9;
}

.review-section-title::after{
  content: '';
  display: block;
  width: 100px;
  padding-top: 3px;
  border-bottom: 3px solid #7467ef;
}

.modal-settings-title{
  font-size: 1em;
  padding-top: 1em !important;
}

.modal-settings-title::before{
  content: '';
  display: inline-block;
  height: 1em;
  border-left: #7467ef 3px solid;
  padding-right: .3em;
}

.review-text{
  padding-top: 1em;
  font-size: .8em;
}

@media(max-width: 1199px){
  .appbar-org-container{
    display: none;
  }
}
.error{
 color:#d32f2f
}
iframe#webpack-dev-server-client-overlay{display:none!important}

