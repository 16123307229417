.tooltip-wrapper {
  @apply invisible absolute bottom-7 left-[8px] translate-x-[-50%] z-10 w-[580px] transition-opacity duration-300 flex justify-center;
}

.tooltip {
  @apply px-5 py-3 text-sm text-gray-400 font-normal text-center transition-opacity duration-300 bg-[#2B2D31] shadow-md rounded-md border-t border-gray-600;
}

.tooltip-arrow {
  @apply invisible absolute bottom-5 left-[8px] translate-x-[-50%] z-20 w-[16px] h-[8px] transition-opacity duration-300 bg-[#2B2D31];
  clip-path: polygon(0% 0%,50% 100%,100% 0%);
}

.tooltip-i {
  @apply rounded-full bg-gray-light text-gray-900 text-xs font-serif font-semibold w-4 h-4 flex justify-center items-center;
}

.has-tooltip {
  @apply relative inline-flex;
}

.has-tooltip:hover .tooltip-wrapper,
.has-tooltip:hover .tooltip-arrow {
  @apply visible;
}